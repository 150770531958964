<template>
  <div class="mx-auto grid grid-cols-2 bg-grey">
    <div class="w-44">
      <img src="./../assets/Logo.png" alt="" />
    </div>
    <div class="justify-self-end mt-6 mb-6 mr-6">
      <button
        class="
          justify-center
          py-2
          px-4
          border border-transparent
          rounded-md
          shadow-sm
          text-sm
          font-medium
          text-white
          bg-avarto
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
        "
        @click="logout()"
      >
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Nav",
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped></style>
