import { createRouter, createWebHistory } from "vue-router";
import firebase from "firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      layout: "home",
    },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/verarbeitungshinweise",
    name: "DataEnra",
    component: () => import("../views/DataEnra.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../components/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/reset",
    name: "Reset",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/event",
    name: "Event",
    component: () => import("../views/Event.vue"),
    meta: {
      auth: true,
      layout: "logged-in",
    },
  },
  {
    path: "/technologie",
    name: "Technologie",
    component: () => import("../views/Technologie.vue"),
    meta: {
      auth: true,
      layout: "logged-in",
    },
  },
  {
    path: "/strategisch",
    name: "Strategie",
    component: () => import("../views/Strategie.vue"),
    meta: {
      auth: true,
      layout: "logged-in",
    },
  },
  // {
  //   path: "/meeting/:roomName",
  //   name: "Meeting",
  //   component: () => import("../views/Meeting.vue"),
  //   meta: {
  //     auth: true,
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      if (to.query.next != null) {
        next(`${to.query.next}`);
      } else {
        next();
      }
    } else {
      if (to.matched.some((record) => record.meta.auth)) {
        next({
          path: "/login",
          query: {
            next: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  });
});

export default router;
