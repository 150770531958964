<template>
  <div>
    <NavLoggedIn />
    <router-view class="" />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NavLoggedIn from "@/components/NavLoggedIn.vue";

export default {
  name: "Home",
  components: {
    Footer,
    NavLoggedIn,
  },
};
</script>
