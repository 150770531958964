<template>
  <div class="py-4 bg-grey w-full h-16">
    <div class="w-10/12 mx-auto text-center md:w-7/12">
      <div class="grid grid-cols-2 gap-4">
        <a
          href="https://www.arvato-systems.de/impressum"
          target="_blank"
          class="justify-self-end hover:underline"
        >
          Impressum
        </a>
        <a
          href="https://www.arvato-systems.de/datenschutzhinweise-cyber-security-roundtable"
          target="_blank"
          class="justify-self-start hover:underline"
        >
          Datenschutz
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
