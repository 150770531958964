<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <Livechat :snippet="'61533b83d326717cb683bfe6/1fgmi0vc0'" />
  </div>
</template>

<script>
import Livechat from "./components/Livechat";
const default_layout = "default";
export default {
  components: {Livechat},
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
