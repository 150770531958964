<template>
  <div>
    <Nav />
    <router-view class="" />
    <FooterHome />
  </div>
</template>

<script>
import FooterHome from "@/components/FooterHome.vue";
import Nav from "@/components/Nav.vue";

export default {
  name: "Home",
  components: {
    FooterHome,
    Nav,
  },
};
</script>
