<template>
  <div>
    <router-view class="" />
    <FooterHome />
  </div>
</template>

<script>
import FooterHome from "@/components/FooterHome.vue";

export default {
  name: "Home",
  components: {
    FooterHome,
  },
};
</script>
