<template>
  <div class="my-4 text-center">
    <button class="mx-2" @click="$router.push('/')">Home</button>
    <button class="mx-2" @click="$router.push('/login')">Login</button>
    <button class="mx-2" @click="$router.push('/register')">Register</button>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Nav",
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/login");
        });
    },
  },
};
</script>

<style scoped></style>
