import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import Default from "./layout/Default.vue";
import LoggedIn from "./layout/LoggedIn.vue";
import Home from "./layout/Home.vue";

const firebaseConfig = {
  apiKey: "AIzaSyBMTQc7QQXKEYp5gZsFGIaivpH8yv8SE04",
  authDomain: "arvato-systems-cs-roundtable.firebaseapp.com",
  projectId: "arvato-systems-cs-roundtable",
  storageBucket: "arvato-systems-cs-roundtable.appspot.com",
  messagingSenderId: "972898319616",
  appId: "1:972898319616:web:1660fbf0021d8c186ac7e5",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const app = createApp(App);
app.use(store);
app.use(router);
app.component("default-layout", Default);
app.component("home-layout", Home);
app.component("logged-in-layout", LoggedIn);
app.mount("#app");
